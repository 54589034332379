@import "../global/___variables"

.home
    .home-carousel
        lost-utility clearfix
        .carousel-item
            display block
            img
                width 100%
        .primary-carousel
            lost-column 176/310 2
            .slick-dots li
                    height 5px
                    width 75px
                    background #d6d6d6
                    +below(500px)
                        display none
            .slick-dots .slick-active
                    background #511f7a
        .secondary-carousel
            lost-column 134/310 2
            .carousel-item
                img
                    padding 0 1.5%
            .slick-arrow
                top 50%
        +below(770px)
            .primary-carousel
            .secondary-carousel
                lost-column 1/1 1 0
