.home .home-carousel {
  lost-utility: clearfix;
}
.home .home-carousel .carousel-item {
  display: block;
}
.home .home-carousel .carousel-item img {
  width: 100%;
}
.home .home-carousel .primary-carousel {
  lost-column: 176/310 2;
}
.home .home-carousel .primary-carousel .slick-dots li {
  height: 5px;
  width: 75px;
  background: #d6d6d6;
}
@media only screen and (max-width: 500px) {
  .home .home-carousel .primary-carousel .slick-dots li {
    display: none;
  }
}
.home .home-carousel .primary-carousel .slick-dots .slick-active {
  background: #511f7a;
}
.home .home-carousel .secondary-carousel {
  lost-column: 134/310 2;
}
.home .home-carousel .secondary-carousel .carousel-item img {
  padding: 0 1.5%;
}
.home .home-carousel .secondary-carousel .slick-arrow {
  top: 50%;
}
@media only screen and (max-width: 770px) {
  .home .home-carousel .primary-carousel,
  .home .home-carousel .secondary-carousel {
    lost-column: 1/1 1 0;
  }
}
